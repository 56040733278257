<!--添加订单功能-->
<template>
  <div style="width: 99%">
    <div style="width: 50%;margin: 0 auto">
      <el-steps :active="activeIndex">
        <el-step title="填写客户信息"/>
        <el-step title="填写订单信息"/>
        <el-step title="填写收款信息"/>
        <el-step title="确认订单信息"/>
        <el-step title="完成"/>
      </el-steps>
    </div>
    <div style="margin-top:30px;display: flex;justify-content: center;text-align: center">
      <!-- 添加客资 -->
      <el-form label-width="100px" :model="customer" :rules="customerRules" v-show="activeIndex == 0" ref="customer">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-row>
              <el-form-item label="客户名" prop="name">
                <el-input v-model="customer.name" placeholder="没有请填暂无" clearable></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="对接日期" prop="createDate">
                <el-date-picker type="date" placeholder="对接日期"
                                v-model="customer.createDate"
                                style="width: 100%;"
                                @change="customerCreateChange"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="客资来源" prop="source">
                <el-cascader
                    :props="{ checkStrictly: true,
                            expandTrigger: 'hover'}"
                    multiple="false"
                    :show-all-levels="false"
                    style="width: 100%;"
                    filterable
                    placeholder="请选择客资来源"
                    :options="sourceArray"
                    v-model="customer.source"
                    ref="source"
                    @change="cascaderClsoe"
                    clearable></el-cascader>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="客服" prop="service">
                <el-select placeholder="请选择客服" v-model="customer.service" filterable style="width: 100%;" @change="serviceChange">
                  <el-option
                      v-for="item in serviceArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
<!--            是否添加预约-->
            <el-row>
              <el-form-item label="添加预约">
                <el-switch
                    v-model="addAppointFlag"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="手机号">
                <el-input v-model="customer.phone" placeholder="请输入客户手机号" clearable></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="微信号">
                <el-input v-model="customer.weChat" placeholder="请输入客户微信号" clearable></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="客户城市">
                <el-input v-model="customer.city" placeholder="请输入客户所在城市" clearable></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="婚礼地点">
                <el-input v-model="customer.weddingVenue" placeholder="请输入客户婚礼地点" clearable></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="备注">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 2}"
                    placeholder="请输入内容"
                    v-model="customer.remark">
                </el-input>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
        <el-button type="primary" @click="customerNext">下一步</el-button>
      </el-form>
      <!-- 添加订单 -->
      <el-form label-width="80px" :model="order" :rules="orderRules" v-show="activeIndex == 1" ref="order">
        <el-row>
          <el-col :span="10">
            <el-form-item label="订单日期" prop="createDate">
              <el-date-picker type="date" placeholder="预约日期"
                              v-model="order.createDate"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="订单店铺" prop="shopId">
              <el-select v-model="order.shopId" placeholder="订单店铺" filterable clearable style="width: 100%"  @change="shopChange">
                <el-option
                    v-for="item in shopArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="订单项目" prop="orderName">
              <el-select v-model="order.orderName" placeholder="请选择订单项目" style="width: 100%"
                         filterable clearable ref="orderName" @change="orderNameChange">
                <el-option
                    v-for="item in orderNameArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="化妆师" prop="orderCosmetics" :rules="orderCosmeticsCheck ? orderRules.orderCosmetics : [{ required:false}]">
              <el-select v-model="order.orderCosmetics" placeholder="化妆师" filterable clearable style="width: 100%"  @change="cosmeticsChange">
                <el-option
                    v-for="item in cosmeticsArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="精确婚期" prop="weddingDay">
              <el-date-picker type="date" placeholder="请选择精确婚期"
                              v-model="order.weddingDay"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="订单号">
              <el-input v-model="order.orderNo" placeholder="请填写订单号"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="订单备注">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="order.orderRemark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="margin-left: 3%">
          <el-button @click="previous">上一步</el-button>
          <el-button type="primary" @click="orderNext">下一步</el-button>
        </el-form-item>
      </el-form>
      <el-form label-width="80px" :model="process" :rules="processRules" v-show="activeIndex === 2" ref="process">
        <el-row>
          <el-col :span="10">
            <el-form-item label="收款项目" prop="proceedsName">
              <el-select placeholder="请选择收款项目" v-model="process.proceedsName" style="width: 100%;" @change="proceedsNameChange">
                <el-option
                    v-for="item in proceedsNameArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="收款方式" prop="payment">
              <el-cascader
                  :props="{checkStrictly: true,
                            expandTrigger: 'hover',
                            label: 'name'}"
                  multiple="false"
                  :show-all-levels="false"
                  style="width: 100%;"
                  placeholder="请选择收款方式"
                  :options="paymentArray"
                  v-model="process.payment"
                  ref="payment"
                  @change="cascaderClsoe"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="订单总价">
              <el-switch
                  v-model="check"
                  active-color="#13ce66">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="check">
          <el-col :span="10">
            <el-form-item label="订单总价">
              <el-input v-model="process.orderPrice" placeholder="请输入订单总价"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="收款金额" prop="spareMoney">
              <el-input v-model.number="process.spareMoney" placeholder="请输入收款金额"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="check">
          <el-col :span="10">
            <el-form-item label="余额">
              <el-input v-model="process.orderSpare"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="收款人" prop="payee">
              <el-select placeholder="请选择收款人" @change="payeeChange" v-model="process.payee" style="width: 100%;" ref="payee">
                <el-option
                    v-for="item in payeeArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="margin-left: 3%">
          <el-button @click="previous">上一步</el-button>
          <el-button type="primary" @click="processNext">下一步</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-left: 16%" v-show="activeIndex === 3">
        <el-descriptions :column="1" :labelStyle="{'font-size': '15px'}" :contentStyle="{'margin-left': '3%','font-size': '14px',}">
          <el-descriptions-item label="客户名">{{customer.name}}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{customer.phone}}</el-descriptions-item>
          <el-descriptions-item label="订单号">{{order.orderNo}}</el-descriptions-item>
          <el-descriptions-item label="订单项目" :contentStyle="{'margin-left': '1.7%'}">{{orderName}}</el-descriptions-item>
          <el-descriptions-item label="收款项目" :contentStyle="{'margin-left': '1.7%'}">{{proceedsName}}</el-descriptions-item>
          <el-descriptions-item v-if="check" label="订单总价" :contentStyle="{'margin-left': '1.7%'}">{{process.orderPrice}}</el-descriptions-item>
          <el-descriptions-item label="收款金额" :contentStyle="{'margin-left': '1.7%'}">{{process.spareMoney}}</el-descriptions-item>
          <el-descriptions-item label="收款人" :contentStyle="{'margin-left': '3.2%'}">{{payee}}</el-descriptions-item>
        </el-descriptions>
        <div style="margin-top: 3%">
          <el-button @click="previous">上一步</el-button>
          <el-button type="primary" :loading="addLoading" @click="orderConfirm">确认入款</el-button>
        </div>
      </div>
      <div  v-show="activeIndex === 4">
        <el-result icon="success" title="订单添加成功">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="toOrderList">查看订单</el-button>
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //快速添加订单
  name: "add-order",
  created() {
    this.pageInit()
  },
  data() {
    return {
      activeIndex: 0,
      customer: {
        name: "",
        city: "",
        phone: "",
        weChat: "",
        createDate: new Date(),
        weddingDay: "",
        weddingVenue: "",
        source: "",
        service: "",
        remark: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
        appoint:{
          createDate: new Date(),
          appointDate: new Date(),
          appointTime: "12:00",
          inviter: "",
          appointName: "",
          appointShop: "",
          appointDress: "",
          appointCosmetics: "",
          type: "售前预约",
          tenantCrop: localStorage.getItem("tenantCrop"),
        },
      },
      customerRules: {
        name: [{required: true, message: '请输入客户名', trigger: 'blur'}],
        source: [{required: true, message: '请选择客资来源', trigger: 'change'}],
        service: [{required: true, message: '请选择客服', trigger: 'change'}],
        createDate: [{required: true, message: '请选择对接日期', trigger: 'change'}],
        appoint:{
          createDate: [{required: true, message: '请选择预约日期', trigger: 'change'}],
          appointName: [{required: true, message: '请选择预约项目', trigger: 'change'}],
        }

      },
      sourceArray: [],
      serviceArray: [],
      order: {
        createDate: new Date(),
        shopId: "",
        orderCosmetics: "",
        orderNo: "",
        orderName: "",
        weddingDay: "",
        orderRemark: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      orderRules: {
        createDate: [{required: true, message: '请选择订单日期', trigger: 'change'}],
        shopId: [{required: true, message: '请选择订单店铺', trigger: 'change'}],
        orderCosmetics: [{required: true, message: '项目为婚礼跟妆时必填', trigger: 'change'}],
        orderName: [{required: true, message: '请选择订单项目', trigger: 'change'}],
        weddingDay: [{required: true, message: '请选择精确婚期', trigger: 'change'}],
      },
      orderNameArray: [],
      shopArray: [],
      cosmeticsArray: [],
      //化妆师动态必填
      orderCosmeticsCheck:false,
      process: {
        proceedsName: "",
        payment: "",
        orderPrice: "",
        spareMoney: "",
        orderSpare: "",
        payee: "",
        tenantCrop: localStorage.getItem("tenantCrop"),
        createDate: new Date()
      },
      processRules: {
        proceedsName: [{required: true, message: '请选择收款项目', trigger: 'change'}],
        payment: [{required: true, message: '请选择收款方式', trigger: 'change'}],
        spareMoney: [{required: true, message: '请输入收款金额', trigger: 'blur'}],
        payee: [{required: true, message: '请选择收款人', trigger: 'change'}]
      },
      proceedsNameArray: [],
      inviterArray: [],
      appointNameArray: [],
      appointDressArray: [],
      paymentArray: [],
      payeeArray: [],
      check: false,
      orderName: "",
      payee: "",
      proceedsName: "",
      addLoading: false,
      addAppointFlag:false,
    }
  },
  watch: {
    'process.orderPrice'(valOne) {
      this.process.orderSpare = valOne - this.process.spareMoney;
    },
    'process.spareMoney'(valOne) {
      this.process.orderSpare = this.process.orderPrice - valOne;
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryProjectsIds(this.$projectsType.order).then(response => {
        this.orderNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        let shops = localStorage.getItem("shops").split(",").map(Number)
        this.shopArray = JSON.parse(response.data.data).filter(s => shops.includes(s.value));
      })
      this.$selectUtils.queryCosmeticsIds().then(response => {
        this.cosmeticsArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryProjectsIds(this.$projectsType.proceeds).then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryPaymentIds().then(response => {
        this.paymentArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryPayeeIds().then(response => {
        this.payeeArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryEmpIds().then(response => {
        this.inviterArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryAppProjectsIds(this.$projectsType.appoint
          ,1).then(response => {
        this.appointNameArray = JSON.parse(response.data.data);
        let defChoose=this.appointNameArray.filter(item=>item.name==='试纱');
        this.customer.appoint.appointName=defChoose[0].value;
      })
      this.$selectUtils.queryDressIds().then(response => {
        this.appointDressArray = JSON.parse(response.data.data);
      })
    },
    customerNext() {
      this.$refs['customer'].validate((valid) => {
        if (valid) {
          this.next()
        }
      })
    },
    orderNext() {
      this.$refs['order'].validate((valid) => {
        if (valid) {
          this.next()
        }
      })
    },
    processNext() {
      this.$refs['process'].validate((valid) => {
        if (valid) {
          this.next()
        }
      })
    },
    next() {
      this.activeIndex++
    },
    previous() {
      this.activeIndex--
    },
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false
      this.$refs.payment.dropDownVisible = false
    },
    orderNameChange(val) {
      let orderNameChose = this.orderNameArray.filter(s => s.value === val)[0]
      this.orderName = orderNameChose.name
      if (orderNameChose.name.includes("妆")){
        let defChoose=this.appointNameArray.filter(item=>item.name==='试妆');
        this.customer.appoint.appointName=defChoose[0].value;
      }
      //如果订单项目是婚礼跟妆则化妆师必填
      this.orderCosmeticsCheck=this.orderName==='婚礼跟妆';
    },
    payeeChange(val) {
      this.payee = this.payeeArray.filter(s => s.value === val)[0].name
    },
    proceedsNameChange(val) {
      this.proceedsName = this.proceedsNameArray.filter(s => s.value === val)[0].name
    },
    orderConfirm() {
      this.addLoading = true
      if ((typeof this.customer.source) === 'object') {
        this.customer.source = this.customer.source[this.customer.source.length -1]
      }
      if ((typeof this.process.payment) === 'object') {
        this.process.payment = this.process.payment[this.process.payment.length -1]
      }
      this.$axios.post('/order/directAddOrder',
    {customer: this.customer,
          order: this.order,
          appoint: this.customer.appoint,
          addAppointFlag:this.addAppointFlag,
          process: this.process})
        .then(response => {
          this.addLoading = false
          if (response.data.code === 200) {
            this.next()
          } else {
            this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error',
              duration: 1500,
            });
          }
      })
    },
    //客户对接时间
    customerCreateChange(val){
      this.order.createDate=val;
      this.customer.appoint.createDate=val;
      this.customer.appoint.appointDate=val;
    },
    //客服
    serviceChange(val){
      this.customer.appoint.inviter=val;
    },
    shopChange(val){
      this.customer.appoint.appointShop = val
    },
    //订单化妆师
    cosmeticsChange(val){
      this.customer.appoint.appointCosmetics= val;
    },
    toOrderList() {
      this.$router.push("/work/order/order-list")
    },
  }
}
</script>

<style scoped>
/deep/ .el-result {
  padding-left: 20%;
  align-items: normal;
  text-align: unset;
}
/deep/ .el-result__extra {
  padding-left: 1.3%;
}
/deep/ .el-result__icon {
  padding-left: 2.6%;
}
.el-form-item{
  width: 20vw;
}
</style>